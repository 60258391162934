
import React from 'react';
import { ethers } from 'ethers';

import stakingContractABI from './data/ABI.json';
import tokenContractABI from './data/abiPAnft.json';

import './App.css';

const tokenContractAddress = '0xDfe3AC769b2d8E382cB86143E0b0B497E1ED5447';
const stakingContractAddress = '0x882eA36F2031F3EDD6Cc243472f6Bea7195ECaf3';

const createContract = ( address, abi, provider ) => {
    return new ethers.Contract( address, abi, provider.getSigner() );
};

function App() {
    const [ provider,      setProvider      ] = React.useState( null );
    const [ isApproved,    setIsApproved    ] = React.useState( false );
    const [ ownedNFTs,     setOwnedNFTs     ] = React.useState( [] );
    const [ stakedNFTs,    setStakedNFTs    ] = React.useState( [] );
    const [ walletAddress, setWalletAddress ] = React.useState( null );

    const stakingContract = React.useMemo(() => {
        if( provider )
            return createContract( stakingContractAddress, stakingContractABI, provider);
    }, [ provider ]);

    const tokenContract = React.useMemo(() => {
        if( provider )
            return createContract( tokenContractAddress, tokenContractABI, provider);
    }, [ provider ]);


    const handleApproveStaking = async () => {
        if( !isApproved ){
            await tokenContract.setApprovalForAll( stakingContractAddress, true );
            const isApproved = await tokenContract.isApprovedForAll( walletAddress, stakingContractAddress );
            setIsApproved( isApproved );
        }
    };

    const handleConnect = async () => {
        if( window.ethereum ){
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            if( accounts && accounts.length ){
                handleConnected( accounts );
            }
        }
    };

    const handleConnected = async ( accounts ) => {
        if( !provider ){
            const tmpProvider = new ethers.providers.Web3Provider(window.ethereum);
            setProvider( tmpProvider );
            setWalletAddress( accounts[0] );
        }

        if( tokenContract ){
            loadTokens( accounts[0] );

            const isApproved = await tokenContract.isApprovedForAll( accounts[0], stakingContractAddress );
            setIsApproved( isApproved );
        }
    };

    const handleTokenSelected = ( evt, token ) => {
        if( token.isStaked ){
            //enqueue for unstaking
            const newStaked = { ...stakedNFTs };
            newStaked[ token.tokenId ].isSelected = !newStaked[ token.tokenId ].isSelected;
            setStakedNFTs( newStaked );
        }
        else{
            //enqueue for staking
            const newOwned = { ...ownedNFTs };
            newOwned[ token.tokenId ].isSelected = !newOwned[ token.tokenId ].isSelected;
            setOwnedNFTs( newOwned );
        }
    };

    const init = async () => {
        if( walletAddress ){
            handleConnected([  ]);
        }
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        if( accounts && accounts.length ){
            handleConnected( accounts );
        }
    };

    const handleStakeToken = async () => {
        const selectedTokenIDs = Object.values(ownedNFTs).filter( t => t.isSelected ).map( t => t.tokenId );
        if( !(selectedTokenIDs && selectedTokenIDs.length) ){
            alert("No NFTs selected! Select one or more to stake, then try again.");
            return;
        }

        try{
            await stakingContract.estimateGas.multiStakeToken(tokenContractAddress, selectedTokenIDs);
            const result = await stakingContract.multiStakeToken(tokenContractAddress, selectedTokenIDs);
            if( result ){
                alert('Transaction pending!\n\nPlease wait to see your updated collection.');
                await result.wait();
            }
        }
        catch( err ){
            alert( err.error.message );
        }
    }

    //@notice: Unstakes an NFT
    const handleUnstakeToken = async () => {
        const selectedTokenIDs = Object.values(stakedNFTs).filter( t => t.isSelected ).map( t => t.tokenId );
        if( !(selectedTokenIDs && selectedTokenIDs.length) ){
            alert("No NFTs selected! Select one or more to unstake, then try again.");
            return;
        }

        try{
            await stakingContract.estimateGas.cancelMultiStakes(selectedTokenIDs);
            const result = await stakingContract.cancelMultiStakes(selectedTokenIDs);
            if( result ){
                alert('Transaction pending!\n\nPlease wait to view your updated collection.');
                await result.wait();
            }
        }
        catch( err ){
            alert( err.error.message );
        }
    }


    const loadMetadata = async (tokenId, isStaked) => {
        try{
            //broken
            //const tokenURI = await tokenContract.tokenURI( tokenId );
            const response = await fetch( `https://pluto-alliance.s3.amazonaws.com/${tokenId}.json` );
            if( response.ok ){
                const metadata = await response.json();
                metadata.isSelected = false;
                metadata.isStaked = isStaked;
                metadata.tokenId = tokenId;
                return metadata;
            }
            else{
                return {
                    name: `Token #${tokenId}`,
                    image: 'https://cg.mypinata.cloud/ipfs/QmeiXdxZ3A8ktAiWbtBQgrfyKPU6cMrfNsmkYMCvthBcdV',
                    isSelected: false,
                    isStaked,
                    tokenId
                };
            }
        }
        catch( err ){
            console.warn({ err });
            return {
                name: `Token #${tokenId}`,
                image: 'https://cg.mypinata.cloud/ipfs/QmeiXdxZ3A8ktAiWbtBQgrfyKPU6cMrfNsmkYMCvthBcdV',
                isSelected: false,
                isStaked,
                tokenId
            };
        }
    };

    const loadTokens = async ( account ) => {
        try{
            const balance = await tokenContract.balanceOf( account )
                .then( balance => parseInt( balance ) );

            if( balance > 0 ){
                const tokens = {};
                for( let i = 0; i < balance; ++i ){
                    const tokenId = (await tokenContract.tokenOfOwnerByIndex( account, i )).toString();
                    tokens[ tokenId ] = await loadMetadata( tokenId, false );
                }
                setOwnedNFTs( tokens );
            }
            
            const tokenIds = await stakingContract.getStakedTokens( account );
            if( tokenIds && tokenIds.length ){
                const tokens = {};
                for( let i = 0; i < tokenIds.length; ++i ){
                    const tokenId = tokenIds[ i ].toString();
                    tokens[ tokenId ] = await loadMetadata( tokenId, true );
                }
                setStakedNFTs( tokens );
            }
        }
        catch( err ){
            alert( "An error occurred while loading your tokens.  Please refresh the page to try agian." );
            debugger;
        }
    };

    //Use effect for the state of getNftData
    React.useEffect(() => {
        init();
    }, [ provider ])


    /**
     *   H T M L   E L E M E N T S
     **/

    const connectText = walletAddress ?
        "Connected: "+ walletAddress.substring(0,6) +'...'+ walletAddress.substring(walletAddress.length-4) : 
        "Connect Wallet";

    return (
        <div className="App">
            <div className="container">
                <div className="nav-container">
                    <button className='connect-button' onClick={handleConnect}>{connectText}</button>
                </div>
                <div className='text'>
                    Staked Pluto Alliance Aliens
                </div>
                <div className='NFTStakedBox'>
                    <div className='staked-nft-container'>
                        {Object.values(stakedNFTs).map( token => (
                            <div key={token.tokenId} className={'card staked-nft-card'+ (token.isSelected ? ' selected' : '')}  onClick={( evt ) => handleTokenSelected( evt, token )}>
                                <img src={token.image} className='nft-image' alt='the NFT' />
                                <div className='card content'>
                                    <div className='card content-item'>
                                        {token.name}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='button-container'>
                    <button id='approveButton' className={'approve-button' +( isApproved ? ' approved' : '' )} onClick={handleApproveStaking}>
                        Approve
                    </button>

                    <button id='stakeButton' className='stake-button' onClick={handleStakeToken}>
                        Stake
                    </button>

                    <button id='unstakeButton' className='unstake-button' onClick={handleUnstakeToken}>
                        Unstake
                    </button>
                </div>

                <div className='nft-container'>
                    {Object.values( ownedNFTs ).map( token => (
                        <div key={token.tokenId} className={'card nft-card'+ (token.isSelected ? ' selected' : '')} onClick={( evt ) => handleTokenSelected( evt, token )}>
                            <img src={token.image} className='nft-image' alt='the NFT' />
                            <div className='card content'>
                                <div className='card content-item'>
                                {token.name}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className='footer'></div>
            </div>
        </div>
    );
}
export default App;